<template>
  <dash-page no-side  >

    <template #default>

      <v-sheet color="black" elevation="3" class="wsRoundedHalf mt-2" style="position: relative">

        <v-img transition="xxx" style="opacity: 0.7" class="wsRoundedHalf" aspect-ratio="1.9" :src="entity.img"                    v-if="entity.img"  />
        <v-img transition="xxx" style="opacity: 0.7" class="wsRoundedHalf" aspect-ratio="1.9"  src="@/assets/img/course_blank.jpg" v-else />

        <div class="pa-6" style="position: absolute; width: 100%;  bottom: 0">

          <v-sheet :color="wsACCENT" dark class="pa-6 pb-9 pt-16 wsRoundedHalf " >
            <div class="d-flex justify-space-between">
              <h1 style="line-height: 1.2"> {{ entity.name }}</h1>
              <h2 v-if="entity.payed"> {{ entity.price }} {{ BUSINESS_CURRENCY }}</h2>
              <h2 v-else> {{ $t('Free') }}</h2>
            </div>
            <div class="d-flex justify-space-between align-center">
              <div>
                <h5 class="font-weight-light mt-3"> {{ entity.short_description }}</h5>
                <div class="d-flex mt-3 align-center">
                  <v-chip  class="mr-2" outlined>
                    <v-icon small class="mr-2">{{ entity.online ?  'mdi-cast-education' : 'mdi-laptop' }}</v-icon>
                    <h5>{{ entity.online ? $t('Online') : $t('Offline') }}</h5>
                  </v-chip>
                  <h5 >
                    {{ $t('Modules')}} : {{ entity.modules.length }}
                    <v-icon>mdi-circle-small</v-icon>
                    {{ $t('Topics')}} : {{ getTopicsLength()  }}
                    <v-icon>mdi-circle-small</v-icon>
                    {{ $t('Components')}} : {{ getComponentsLength() }}
                  </h5>
                </div>
              </div>

              <v-sheet class="d-flex mt-6 " color="transparent" >
                <div v-if="!entity.enrolled">
                  <div v-if="!entity.payed">
                    <v-btn @click="handleEnrollOnCourse" class="mt-3 noCaps" large  outlined     > {{ $t('Enroll') }}</v-btn>
                  </div>
                  <div v-else>
                    <v-btn @click="displayBuyCourse()"  class="mt-3 noCaps" large  outlined > {{ $t('Buy') }}</v-btn>
                  </div>
                </div>
                <div v-else>
                  <v-chip color="green lighten-1 px-6" dark large>
                    <v-icon class="mr-2" >mdi-check</v-icon>
                    <h4>  {{ $t('Enrolled') }}</h4>

                  </v-chip>
                </div>


              </v-sheet>

            </div>

          </v-sheet>

        </div>

      </v-sheet>

      <v-sheet class="wsRoundedHalf  mt-6  pa-6 pt-9 px-10">

        <div class="d-flex">
          <div style="width: 100%" class="mr-13">
            <h2 :style="`color : ${wsACCENT}`" >{{$t('CourseDescription')}}</h2>
            <div class="mt-6" v-html="entity.description" />
          </div>
          <!-- SIDEBAR INFORMATION -->
          <v-sheet color="transparent" min-width="400">
            <v-sheet class="mt-9"  color="transparent" v-for="(module,i) in entity.modules" :key="`module${i}`">

              <v-sheet  dark :color="wsACCENT" class="wsRoundedHalfTop pa-3">
                <h4 class="text-center">{{ module.name }}</h4>
              </v-sheet>
              <v-sheet :color="wsLIGHTCARD" class="px-6 pb-6 wsRoundedHalfBottom">
                <v-simple-table style="background: transparent" dense>
                  <tbody>

                  <template v-for="(topic,j) in module.topics">
                    <tr :key="`topic${i}${j}`">
                      <td colspan="2" style="border: none"> <h4 class=" text-center py-3">{{ topic.name }}</h4></td>
                    </tr>
                    <tr class="pointer" v-for="(component,k) in topic.components" :key="`component${i}${j}${k}`" >
                      <td style="border: none" class="px-0">
                        <v-icon size="18" :color="wsATTENTION" > {{ getEntityTypeIcon(component.entity_type ) }}</v-icon>
                      </td>
                      <td style="border: none">
                        <h5 class="font-weight-light"> {{ component.name }} </h5>
                      </td>
                    </tr>
                  </template>
                  </tbody>
                </v-simple-table>
              </v-sheet>


            </v-sheet>
          </v-sheet>
        </div>

      </v-sheet>

      <!-- Enrollment Success Dialog on Course -->
      <wsDialog v-model="displayEnrollSuccess"
                :loading="$store.state.LOADING"
                @save="goToCourse"
                :title="$t('EnrollmentSuccess')"
                :cancel-text="$t('Close')"
                :saveText="$t('GotoCourse')">

        <h5 class="font-weight-medium text-center mb-3">{{ $t('EnrollmentSuccessMessage') }}</h5>

      </wsDialog>

      <!-- Enroll on Course -->
      <wsDialog v-model="displayEnroll"
                width="700"
                :loading="$store.state.LOADING"
                @save="enrollOnCourse"
                :title="$t('EnrollOnCourse')"
                :saveText="$t('Enroll')">

        <!-- Scheduled course -->
        <div v-if="entity.scheduled">
          <h5 class="font-weight-medium mb-3">{{ $t('ChooseEnrollmentSchedule') }}:</h5>
          <v-simple-table >
            <thead>
            <tr>
              <th></th>
              <th></th>
              <th :style="`color : ${wsACCENT}`"> {{ $t('Education Start') }}</th>
              <th :style="`color : ${wsACCENT}`"> {{ $t('Education End') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(wave,i) in enrollmentWaves" :key="i"
                @click="enrollmentData.enrollment_wave_id = wave.uuid"
                class="pointer">
              <td>
                <v-btn @click="enrollmentData.enrollment_wave_id = wave.uuid"
                       :color="enrollmentData.enrollment_wave_id === wave.uuid ?  'green lighten-2' : 'grey' "
                       small icon
                       class="mt-1">
                  <v-icon small>{{ enrollmentData.enrollment_wave_id === wave.uuid ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline'}}</v-icon>
                </v-btn>
              </td>
              <td>
                <h5>{{ wave.name }}</h5>
              </td>

              <td>
                <v-chip class="my-1 mr-2" small dark :color="wsACCENT"  >
                  <v-icon small class="mr-1" > mdi-calendar</v-icon>
                  <h4 class="font-weight-medium">{{wave.education_start }}</h4>
                </v-chip>
              </td>
              <td>
                <v-chip class="my-1 mr-2" small outlined   >
                  <v-icon small class="mr-1" > mdi-calendar</v-icon>
                  <h4 class="font-weight-medium">{{wave.education_end }}</h4>
                </v-chip>
              </td>
            </tr>
            </tbody>
          </v-simple-table>
        </div>
        <div v-if="!entity.scheduled">
          <h5 class="font-weight-medium mb-3">{{ $t('OnGorups') }}</h5>
        </div>


      </wsDialog>

      <!-- Course Purchase -->
      <!-- Enroll on Course -->
      <wsDialog v-model="displayPurchase"
                width="900"
                :loading="$store.state.LOADING"
                @save="purchaseCourse"
                :title="$t('PurchaseCourse')"
                :saveText="$t('Buy')">


        <v-simple-table>
          <tbody>
          <tr style="background: transparent" class="pointer"  >
            <td class="px-0 pl-3 py-3" width="1">
                <v-img transition="xxx" v-if="entity.img" class="wsRoundedHalf" height="120" width="200"  :src="entity.img" ></v-img>
                <v-img transition="xxx" v-else class="wsRoundedHalf" height="100" width="200"  src="@/assets/img/course_blank.jpg" ></v-img>
            </td>
            <td valign="top" class="py-3">
                <h4>{{ entity.name }}</h4>
                <h5 class="font-weight-light mt-3">
                  {{ entity.short_description }}
                </h5>
                <h5 class="font-weight-light mt-3">
                  Модулей : {{entity.modules.length }}
                </h5>
            </td>
            <td valign="top" class="py-3" align="right"  width="150px">

                <h4 :style="`color: ${wsACCENT}`">{{ entity.price }} {{ BUSINESS_CURRENCY }}</h4>


            </td>
          </tr>
          </tbody>
        </v-simple-table>
        <v-divider class="my-3" />
        <h4 class="text-right">{{ $t('Total') }}: {{ entity.price }} {{ BUSINESS_CURRENCY }}</h4>

        <!-- Scheduled course -->
        <div v-if="entity.scheduled">
          <h5 class="font-weight-medium mb-3">{{ $t('ChooseEnrollmentSchedule') }}:</h5>
          <v-simple-table >
            <thead>
            <tr>
              <th></th>
              <th></th>
              <th :style="`color : ${wsACCENT}`"> {{ $t('Education Start') }}</th>
              <th :style="`color : ${wsACCENT}`"> {{ $t('Education End') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(wave,i) in enrollmentWaves" :key="i"
                @click="enrollmentData.enrollment_wave_id = wave.uuid"
                class="pointer">
              <td>
                <v-btn @click="enrollmentData.enrollment_wave_id = wave.uuid"
                       :color="enrollmentData.enrollment_wave_id === wave.uuid ?  'green lighten-2' : 'grey' "
                       small icon
                       class="mt-1">
                  <v-icon small>{{ enrollmentData.enrollment_wave_id === wave.uuid ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline'}}</v-icon>
                </v-btn>
              </td>
              <td>
                <h5>{{ wave.name }}</h5>
              </td>

              <td>
                <v-chip class="my-1 mr-2" small dark :color="wsACCENT"  >
                  <v-icon small class="mr-1" > mdi-calendar</v-icon>
                  <h4 class="font-weight-medium">{{wave.education_start }}</h4>
                </v-chip>
              </td>
              <td>
                <v-chip class="my-1 mr-2" small outlined   >
                  <v-icon small class="mr-1" > mdi-calendar</v-icon>
                  <h4 class="font-weight-medium">{{wave.education_end }}</h4>
                </v-chip>
              </td>
            </tr>
            </tbody>
          </v-simple-table>
        </div>



      </wsDialog>


    </template>


    <template #side>
      <v-img transition="xxx" v-if="entity.img" class="wsRoundedHalf pointer" :src="entity.img" width="286" height="160"   />
      <v-img transition="xxx" class="wsRoundedHalf" width="286" height="160" v-else src="@/assets/img/course_blank.jpg" />
      <div class="d-flex mt-3 align-center">
        <v-icon class="mr-2">mdi-cash</v-icon>
        <h2 >{{ entity.price }} {{ BUSINESS_CURRENCY }}</h2>
      </div>

      <v-btn class="mt-3" large  block outlined> {{ $t('Enroll') }}</v-btn>

    </template>

  </dash-page>

</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "studentCourseInfo",
  props : ['uuid'],
  data() {
    return {
      entity : {},
      enrollmentData : {},
      displayEnrollSuccess    :  false,
      displayPurchase : false,
      displayEnroll : false,
      enrollmentWaves : []
    }
  },
  methods : {
    ...mapActions('courses', [  'GET_COURSE_FULL' , 'ENROLL_ON_COURSE' , 'GET_ENROLLMENT_WAVES_STUDENT' ]),
    ...mapActions('cart', [  'ORDER_COURSE'  ]),


    goToCourse() {
      this.$router.push(this.businessDashLink('courses/' + this.uuid + '/student'))
    },
    displayBuyCourse() {
      this.displayPurchase = true
    },
    purchaseCourse() {
      let data = {
        course_id : this.uuid,
        origin : this.DEVICE
      }
      let result = this.ORDER_COURSE(data)
      if ( !result ) { return this.notify( this.$t('NetworkError') ) }
    },
    handleEnrollOnCourse() {

      this.enrollmentData = {
        uuid : this.entity.uuid
      }

      if ( !this.entity.online ) {
        this.enrollOnCourse()
      } else {
        this.displayEnrollWindow()
      }

    },
    async displayEnrollWindow() {

      if ( this.entity.scheduled ) {
        this.enrollmentData = {
          uuid : this.entity.uuid,
          enrollment_wave_id : null
        }
        this.enrollmentWaves = await this.GET_ENROLLMENT_WAVES_STUDENT(this.uuid) || [] ;
      }

      this.displayEnroll = true
    },

    async enrollOnCourse() {
      if (!this.entity.payed) {

        if ( this.entity.online && this.entity.scheduled ) {
          if ( !this.enrollmentData.enrollment_wave_id ) {
            return this.notify(this.$t('PleaseSelectEnrollmentDate'))
          }
        }

        let result = await this.ENROLL_ON_COURSE(this.enrollmentData);
        if ( !result ) { return this.notify( this.$t('NetworkError') ) }
        this.entity.enrolled = true

        this.displayEnroll = false
        this.displayEnrollSuccess = true

      } else {
        this.notify('PleasePurchaseCourse')
      }

    },
    // Technical

    getEntityTypeIcon( type ) {

      let icon = ''
      switch ( type ) {
        case 'lecture'    :  icon = 'mdi-book-open-page-variant'   ; break;
        case 'task'       :  icon = 'mdi-text-box-check-outline'   ; break;
        case 'task_video' :  icon = 'mdi-cast-education'           ; break;
        case 'video'      :  icon = 'mdi-video'                    ; break;
        case 'file'       :  icon = 'mdi-file'                     ; break;
        default : icon = 'mdi-bookmark'
      }
      return icon

    },
    getTopicsLength() {
      let i = 0
      this.entity.modules.forEach((module) => {
        i += module.topics.length
      })
      return i
    },
    getComponentsLength() {
      let i = 0
      this.entity.modules.forEach((module) => {
        module.topics.forEach((topic) => {
          i += topic.components.length
        })

      })
      return i
    },
    async initPage(){
      this.entity = await this.GET_COURSE_FULL(this.uuid) || this.entityDataDefault
    },
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>